export const en = {
  translation: {
    notificationContent:
      "This website does not make any kind of delivery or distribution. We provide a way for people in need to post about their needs. By that the distributors can find them easily and distributors can post about their services as well.",
    homeWelcomeTitle: "Keep the world running",
    homeWelcomeSubTitle:
      "When the world goes into lockdown, everything stops. But we can't let people die because of the lockdown. Let's make sure everyone gets what they need to survive.",
    mostRecentDistributors: "Most recent distributors",
    FirstSpecialNote: "Special note",
    SecondSpecialNote: "Hotline",
    addRequestSubTitle: "State your needs and make a request",
    infoTitle: "Info",
    info:
      "Please try to contact the distributors in your area first. Only make a request if you have no other way to obtain the basics. If there are other people in your area who face the same difficulty as you, please add their information also. Do not go around the village to get the information. Use your phone or just shout to the neighbour",
    contactPersonNameInputLabel: "Contact person name",
    numberOfPeopleInputLabel: "Number of people *",
    contactNumberInputLabel: "Contact Number *",
    categoriesInputLabel: "Items needed *",
    cityInputLabel: "City *",
    termsAndConditionDetails:
      "I give my consent to share the above details publicly and I have read and agree to the",
    termsAndConditionLink: "Terms and Conditions",
    privacyPolicy: "Privacy Policy",
    submitButton: "Submit",
    cancelButton: "Cancel",
    addNewRequestInputLabel: "Add request",
    addNewRequestSubtitleInputLabel: "State your needs and make a request",
    addNewDistributorInputLabel: "Add distributor",
    addNewDistributorSubtitleInputLabel: "Enter new distributor details",
    citiesInputLabel: "City *",
    phoneNumberInputLabel: "Contact number *",
    websiteInputLabel: "Website",
    emailInputLabel: "Email",
    infoDistributor:
      "Please make sure the content is correct. We will approve distributors only after validating the information. If the information is incorrect, we will not approve it.",
    infoDistributorItems:
      "There should be at least one item or one item pack. Make sure you fill all the required fields denoted by *",
    items: "Items",
    itemsPacks: "Items packs",
    add: "Add",
    nameInputLabel: "Name *",
    removeItem: "Remove",
    submitReview: "Submit for review",
    helpContent: "Showing the most matching 100 cities based on your input.",
    helpItemPacks: "Please add a comma separated list.",
    startType: "Start typing...",
    bannerImageUrlInputLabel: "Banner Image URL",
    bannerImageUploadDetails:
      "If you have the item details in a banner, please upload it to a service like :",
    bannerImageUploadDetailsTwo: "and paste the 'direct link' here.",
    priceInputLabel: "Price",
    bottomGitHubDetails:
      "If you are a developer, please help improve this project. The code is on GitHub:",
    contactUs: "Contact Us",
    citiesWithMostRequest: "Cities with most requests ",
    latestRequests: "Latest requests",
    requestForHelp: "Request for help",
    selectCity: "select city",
    selectDistrict: "select District",
    aboutUsInputLabel: "About Us",
    aboutUsSubtitleInputLabel: "Who we are and what we are trying to do",
    contactInputLabel: "Contact Us",
    howToContactInputLabel: "How to contact us",
    requestDistributorInputLabel: "Distributors",
    requestDistributorSubtitleInputLabel:
      "Where they can go and what they have",
    requestPageInputLabel: "Requests",
    requestPageSubtitleInputLabel: "What people need",
    FirstSpecialNoteDetailsHome:
      "This website is free for everyone to use. Please do not abuse it's capabilities. Currently, some areas get all the required items. There are deliveries, mobile stores, online stores, etc. But for some areas there's nothing. The main target of this website is to identify such areas and make sure everyone survives this crisis together.Learn more on our ",
    SecondSpecialNoteDetailsHome:
      "The government has introduced a hotline for the public to submit complaints on difficulties in receiving essential services. The special presidential task force had set up an operation center to coordinate and follow up on taking necessary action to deal with problems in providing services to the public. therefore the general public can submit complaints on receiving essential services through the below contact details.",
    FirstSpecialNoteItem: "Telephone- 0113456200",
    SecondSpecialNoteItem: "Fax - 0112333066 / 0114354882",
    ThirdSpecialNoteItem: "Email - ptf@pmoffice.gov.lk",
    newDistributorNavBar: "Add Distributor",
    surviveTogetherNavBar: "Survive Together",
    showMore: "Show more...",
    emptyRequestContent: "No requests found. Please be patient.",
    contactPersonRequestTable: "Contact person",
    contactNumberRequestTable: "Contact Number",
    noOfPeopleRequestTable: "No of people",
    cityRequestTable: "City",
    createdAtTable: "Created at",
    requestItemTable: "Requested Items",
    termsAndConditionPageInputLabel: "Terms and conditions",
    termsAndConditionSubtitlePageInputLabel:
      "Terms and Conditions for Survive Together",
    cookiesTermsAndConditionPage: "Cookies",
    "items.nameInputLabel": "Name *",
    "items.categoryInputLabel": "Category *",
    "items.priceInputLabel": "Price",
    "items.deliveryFeeInputLabel": "Delivery Fee",
    "itemPacks.nameInputLabel": "Name *",
    "itemPacks.contentInputLabel": "Content *",
    "itemPacks.priceInputLabel": "Price",
    "itemPacks.deliveryFeeInputLabel": "Delivery Fee",
    requestContactHelp:
      "If you don't want to share your phone number publicly, simple put a - in the field. But we strongly recommend you to share it so people can contact you and send what you need.",
    agreeToTerms: "Please read and agree to the terms and conditions first.",
    blog: "Blog",
  },
};
